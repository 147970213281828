<template>
  <div class="row">
    <retry-notification
      :show="error"
      @retry="updateData()"
    />
    <div class="flex xs12">
      <va-card :title="$t('goals.edit')">
        <actions
          slot="actions"
          crud-links="goals"
          :actions="actions"
          :action-data="{id: $route.params.id}"
        />
        <data-form
          :goal="goal"
          :loading="loading"
          @submit="submit"
        />
      </va-card>
    </div>
  </div>
</template>

<script>
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const DataForm = () => import(/* webpackPrefetch: true */ './Form')

export default {
  name: 'goals-edit',
  components: {
    Actions,
    DataForm,
  },
  data () {
    return {
      error: false,
      loading: false,
      actions: ['index', 'new', 'view', 'delete'],
      goal: {},
    }
  },
  created () {
    this.updateData()
  },
  methods: {
    // FIXME
    routeBuilder (id) {
      return `goals/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const goalId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(goalId))
      } catch (err) {
        // console.log('Error fetching goal data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.goal = u.data.data
    },
    async submit (goal) {
      this.loading = true

      const goalId = this.$route.params.id
      try {
        await this.$http.put(this.routeBuilder(goalId), goal)
      } catch (e) {
        // console.log('Error while updating goal', e)
        this.loading = false
        return
      }

      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })
      this.loading = false
    },
  },
}
</script>
